import React from 'react'

interface PContext {
  hideCookieConsent: boolean
  setHideCookieConsent: (hideCookieConsent: boolean) => void
}

const defaultValue = {
  hideCookieConsent: false,
  setHideCookieConsent: () => undefined,
}

export const PageContext = React.createContext<PContext>(defaultValue)

interface Props {
  children: any
}

export const PageContextProvider: React.FC<Props> = (props: Props) => {
  const { children } = props
  const [hideCookieConsent, setHideCookieConsent] = React.useState(false)

  return (
    <PageContext.Provider value={{ hideCookieConsent, setHideCookieConsent }}>
      {children}
    </PageContext.Provider>
  )
}
