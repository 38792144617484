/**
 * SEO component that queries for data with
 *  Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from 'react'
import Helmet from 'react-helmet'
import { useStaticQuery, graphql } from 'gatsby'
import { injectIntl, IntlShape } from 'gatsby-plugin-intl'

interface PreviewImage {
  src: string
  width?: number
  height?: number
  maxWidth?: number
  maxHeight?: number
}

interface Props {
  description?: string
  meta?: Array<any>
  link?: Array<any>
  script?: Array<any>
  previewImage?: PreviewImage
  title: string
  intl: IntlShape
}

const SEO: React.FC<Props> = ({
  description = '',
  meta = [],
  link = [],
  script = [],
  previewImage,
  title,
  intl,
}: Props) => {
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            siteUrl
            title
            description
            author
          }
        }
      }
    `
  )

  const { siteUrl } = site.siteMetadata
  const metaDescription = description || site.siteMetadata.description
  const metaAuthor = site.siteMetadata.author

  const metaArr = [
    {
      name: `description`,
      content: metaDescription,
    },
    {
      property: `og:title`,
      content: title,
    },
    {
      property: `og:site_name`,
      content: site.siteMetadata.title,
    },
    {
      property: `og:description`,
      content: metaDescription,
    },
    {
      property: `og:type`,
      content: `website`,
    },
    {
      name: `twitter:card`,
      content: `summary`,
    },
    {
      name: `twitter:site`,
      content: metaAuthor,
    },
    {
      name: `twitter:creator`,
      content: metaAuthor,
    },
    {
      name: `twitter:title`,
      content: title,
    },
    {
      name: `twitter:description`,
      content: metaDescription,
    },
    {
      name: `google-site-verification`,
      content: `u7PzdvxO8sNKk0O61o70xKqypSde7rAqGbujlrp32Go`,
    },
  ].concat(meta)

  if (previewImage) {
    const ogImage = `${siteUrl}${previewImage.src}`
    metaArr.push({ name: `og:image`, content: ogImage })
    metaArr.push({ name: `og:image:secure_url`, content: ogImage })
    const width = previewImage.width ?? previewImage.maxWidth ?? 0
    const height = previewImage.height ?? previewImage.maxHeight ?? 0
    if (width && height) {
      metaArr.push({ name: `og:image:width`, content: width })
      metaArr.push({ name: `og:image:height`, content: height })
    }

    metaArr.push({ name: `twitter:image`, content: ogImage })
  }

  return (
    <Helmet
      htmlAttributes={{
        lang: intl.locale,
      }}
      title={title}
      titleTemplate={`${site.siteMetadata.title} - %s`}
      link={link}
      script={script}
      meta={metaArr}
    />
  )
}

export default injectIntl(SEO)
