import React from 'react'
import { PageContextProvider } from '../PageContext'
import { ThemeProvider } from '@aristech/components'
import GlobalStyle from './globalStyle'
import defaultTheme from './defaultTheme'
// import 'swiper/swiper-bundle.min.css'
import '../../fonts/urwdin_regular_macroman/stylesheet.css'
import '../../fonts/urwdin_bold_macroman/stylesheet.css'
import '../../fonts/urwdin_italic_macroman/stylesheet.css'
import '../../fonts/aristech-iconography/font/flaticon.css'
import useMedia from 'use-media'

interface Props {
  children: React.ReactNode
}

const PageWrapper: React.FC<Props> = (props: Props) => {
  const { children } = props
  const isPortrait = useMedia({ maxWidth: defaultTheme.protraitBreakpoint })
  const theme = { ...defaultTheme }
  if (isPortrait) {
    theme.scale = 1.1
    // theme.scale = 1.125 // Major second
    // theme.scale = 1.067 // Minor second
  }
  return (
    <PageContextProvider>
      <ThemeProvider theme={theme as any}>
        <>
          <GlobalStyle />
          {children}
        </>
      </ThemeProvider>
    </PageContextProvider>
  )
}

export default PageWrapper
