import { createGlobalStyle } from 'styled-components'

/* @import url("https://use.typekit.net/yws6zzl.css"); */
const GlobalStyle = createGlobalStyle`
  html {
    font-size: 100%; /* =16px */
    -ms-text-size-adjust: 100%;
    -webkit-text-size-adjust: 100%;
    /* scroll-behavior: smooth; */
  }
  body {
    font-family: ${props => props.theme.fontFamily};
    font-weight: 400;
    font-size: 16px;
    line-height: 1.65;
    color: ${props => props.theme.secondary};
    margin: 0;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    @media only screen and (max-width: ${props =>
      props.theme.smallBreakpoint}px) {
      font-size: 14px;
    }
    @media only screen and (max-width: ${props =>
      props.theme.protraitBreakpoint}px) {
      font-size: 12px;
    }
  }
  p {
    margin-bottom: 1.15rem;
  }
  h1, h2, h3, h4, h5 {
    margin: 2.75rem 0 1.05rem;
    font-family: ${props => props.theme.fontFamily};
    font-weight: 400;
    line-height: 1.5 !important;
  }
  h1 {
    margin-top: 0;
    font-size: ${props => props.theme.scale ** (6 - 1)}rem !important;
  }
  h2 {
    font-size: ${props => props.theme.scale ** (6 - 2)}rem !important;
  }
  h3 {
    font-size: ${props => props.theme.scale ** (6 - 3)}rem !important;
  }
  h4 {
    font-size: ${props => props.theme.scale ** (6 - 4)}rem !important;
  }
  h5 {
    font-size: ${props => props.theme.scale ** (6 - 5)}rem !important;
  }
  h6 {
    font-size: 1rem !important;
  }
  small, .text_small {
    font-size: ${props => props.theme.scale ** -1}em;
  }
  article,
  aside,
  details,
  figcaption,
  figure,
  footer,
  header,
  main,
  menu,
  nav,
  section,
  summary {
    display: block;
  }
  audio,
  canvas,
  progress,
  video {
    display: inline-block;
  }
  audio:not([controls]) {
    display: none;
    height: 0;
  }
  hr {
    border: 0;
    width: 100%;
    height: 2px;
    background: linear-gradient(
      90deg,
      rgba(238, 238, 238, 0.2987570028011205) 0%,
      rgba(238, 238, 238, 1) 10%,
      rgba(238, 238, 238, 1) 90%,
      rgba(238, 238, 238, 0) 100%
    );
  }
  .md {
    a,
    a:link,
    a:visited {
      text-decoration: none;
      color: ${props => props.theme.primary};
    }
    a:hover {
      text-decoration: underline;
    }
  }
  [class^="flaticon-"]:before, [class*=" flaticon-"]:before, [class^="flaticon-"]:after, [class*=" flaticon-"]:after {
    margin-left: 0;
    font-size: inherit;
  }
  html[lang="de"] {
    quotes: '„' '“';
  }
  :root {
    --swiper-theme-color: ${props => props.theme.primary};
  }
`

export default GlobalStyle
