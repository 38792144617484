import React from 'react'
import { injectIntl, IntlShape } from 'gatsby-plugin-intl'
import SEO from '../SEO'

interface Props {
  intl: IntlShape
}

const Redirect: React.FC<Props> = ({ intl }: Props) => {
  return <SEO title={`${intl.formatMessage({ id: 'title' })}`} />
}

export default injectIntl(Redirect)
