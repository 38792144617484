module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-gtag/gatsby-browser.js'),
      options: {"plugins":[],"trackingId":"UA-41568419-2","head":true,"anonymize":true},
    },{
      plugin: require('../node_modules/gatsby-plugin-plausible/gatsby-browser.js'),
      options: {"plugins":[],"domain":"aristech.de"},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Aristech GmbH","short_name":"Aristech","start_url":"/","lang":"en","description":"We develop Speech Technology. Text-to-speech, speech recognition, semantic Analytics and natural Voicebots for your company.","background_color":"#fff","theme_color":"#6592e1","display":"minimal-ui","icon":"src/images/aristech-icon.svg","icon_options":{"purpose":"maskable"},"localize":[{"start_url":"/de/","lang":"de","description":"Aristech entwickelt Sprachtechnologie in Heidelberg. Text-to-speech, Spracherkennung, semantische Analyse und natürliche Sprachdialogsysteme für Ihr Unternehmen."}],"legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"642960f457f4ce0a3a5cd320f0bd1102"},
    },{
      plugin: require('../node_modules/gatsby-plugin-intl/gatsby-browser.js'),
      options: {"plugins":[],"path":"/builds/martinmende/website/src/intl","languages":["en","de"],"defaultLanguage":"en","redirect":true,"redirectComponent":"/builds/martinmende/website/src/components/Redirect/index.tsx"},
    },{
      plugin: require('../node_modules/gatsby-plugin-loadable-components-ssr/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
