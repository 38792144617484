import { DefaultTheme } from 'styled-components'

const defaultTheme: DefaultTheme = {
  fontFamily: 'urw-din, Arial, Helvetica, sans-serif',

  gradientStart: 'rgba(62, 62, 201, 1)',
  gradientStop: 'rgba(86, 203, 249, 1)',

  primary: '#6592e1',
  secondary: '#333', // '#3c3744',
  info: '#aab8c2',
  warning: '#ffbc64',
  danger: '#f25050',
  success: '#5dc77a',

  protraitBreakpoint: 600,
  smallBreakpoint: 1050,
  mediumBreakpoint: 1300,

  scale: 1.2, // Minor Third
}

export default defaultTheme
