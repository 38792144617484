/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */
import React from 'react'
import PageWrapper from './src/components/PageWrapper'
// import 'intersection-observer'
// import flexibility from 'flexibility'
// import 'swiper/swiper.scss'
// import 'swiper/components/a11y/a11y.scss'
// import 'swiper/components/controller/controller.scss'
// import 'swiper/components/pagination/pagination.scss'
// import 'swiper/components/navigation/navigation.scss'

export const wrapPageElement = ({ element }) => (
  <PageWrapper>{element}</PageWrapper>
)
